import './App.css';
import { HashRouter as Router } from 'react-router-dom';
import Header from './Components/Header';
import Nav from './Components/Nav';
import Section3D from './Components/3Dsection';
import Intro from './Components/Intro';
import Games from './Components/Games';
import Footer from './Components/Contact';
import Donations from './Components/Donation';

function App() {
  return (
    <Router>
      <div className="App min-h-screen bg-black flex flex-col">
        <Header />
        <Nav />
        <div id="home"><Section3D /></div>
        <div id="about"><Intro /></div>
        <div id="games"><Games /></div>
        <div id="Donations"><Donations/></div>
        <div id="Contact"><Footer/></div>
      </div>
    </Router>
  );
}

export default App;
