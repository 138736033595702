import React from "react";

const Header = () => {
    return (
        <div className="w-full flex justify-center p-4 bg-black">
            <img 
                src="/images/logo.png" 
                alt="Logo" 
                className="h-9 transition-transform duration-300 ease-in-out transform hover:scale-110"
            />
        </div>
    );
};

export default Header;
