import React from "react";

const SectionWithVideo = () => {
    return (
        <div className="relative w-full h-screen">
            <video 
                className="absolute top-0 left-0 w-full h-full object-cover"
                src="/images/BG1.mp4" 
                autoPlay 
                loop 
                muted 
                playsInline 
            />
            <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
            <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white">
                <h1 className="text-4xl font-bold md:text-6xl">Play,<span className="text-blue-400">Learn,</span></h1>
                <h2 className="mt-2 text-2xl italic md:text-4xl">and be <span className="text-blue-400">Light.</span></h2>
                <div className="mt-10">
                    <img src="/images/arrow.png" alt="Scroll down" className="animate-bounce h-10 w-10" />
                </div>
            </div>
        </div>
    );
};

export default SectionWithVideo;
