import React from "react";

const Intro = () => {
  return (
    <div className="relative flex flex-col justify-center items-center min-h-screen p-4 bg-black text-white">
      <video 
        className="absolute top-0 left-0 w-full h-full object-cover"
        src="/images/HouseVideo.mp4" 
        autoPlay 
        loop 
        muted 
        playsInline 
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      <div className="relative z-10">
        <h1 className="text-4xl md:text-6xl font-bold text-center mb-4 text-blue-400">
          TENBYTE GAMES
        </h1>
        <p className="text-base md:text-lg text-center text-gray-400 max-w-2xl text-custom">
          Welcome to TenByte Games, where creativity and faith come together in every pixel. We are a game development studio founded by a Christian couple from Uruguay: Pablo, a video game designer, and Lucía, a teacher. Together, we created TenByte Games as a space to develop playful games that educate and entertain, inspired by Christian values. Our goal is to offer experiences that not only bring joy but also impart knowledge, bridging fun with faith.
        </p>
      </div>
    </div>
  );
}

export default Intro;
