import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

const Nav = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="bg-black text-white w-full ">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
                <div className="flex justify-between items-center h-16">
                    <div className="flex-shrink-0"></div>
                    <div className="hidden md:block">
                        <div className="flex justify-center items-baseline space-x-10">
                            <Link
                                to="#home"
                                smooth
                                className="px-3 py-2 rounded-md text-xl font-medium hover:bg-gray-700 hover:text-white"
                            >
                                Home
                            </Link>
                            <Link
                                to="#about"
                                smooth
                                className="px-3 py-2 rounded-md text-xl font-medium hover:bg-gray-700 hover:text-white"
                            >
                                About
                            </Link>
                            <Link
                                to="#games"
                                smooth
                                className="px-3 py-2 rounded-md text-xl font-medium hover:bg-gray-700 hover:text-white"
                            >
                                Games
                            </Link>
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        <button
                            onClick={toggleMenu}
                            type="button"
                            className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
                        >
                            <span className="sr-only">Open main menu</span>
                            {!isOpen ? (
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M4 6h16M4 12h16M4 18h16"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
            </div>

            <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    <Link
                        to="#home"
                        smooth
                        className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700 hover:text-white"
                    >
                        Home
                    </Link>
                    <Link
                        to="#about"
                        smooth
                        className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700 hover:text-white"
                    >
                        About
                    </Link>
                    <Link
                        to="#games"
                        smooth
                        className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700 hover:text-white"
                    >
                        Games
                    </Link>
                </div>
            </div>
        </nav>
    );
};

export default Nav;


