import React from 'react';
import { FaInstagram, FaTwitter} from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-black text-custom py-6 "  >
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-start">
        <div className="mb-4 md:mb-0 flex flex-col items-start">
          <img src="/images/logo.png" alt="Logo" className="w-32 mb-4" />
          <address className="not-italic">
           Uruguay,Maldonado<br />
         
          </address>
        </div>
        <div className="flex flex-col items-end">
          <p className="text-green-400 mb-2">games@tenbyte.games</p>
          <div className="flex space-x-4 mb-4">
            <a href="https://x.com/TenbyteGames" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="w-6 h-6" />
            </a>
            <a href="https://www.instagram.com/tenbyte_games/?igsh=ZjY5bG41Z2pmOXd0&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="w-6 h-6" />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center mt-4">
        <p>&copy; 2024 TenByte.</p>
      </div>
    </footer>
  );
}

export default Footer;
