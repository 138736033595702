import React, { useEffect, useState } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../services'; // Asegúrate de importar correctamente storage

const Games = () => {
  const [Products, SetProducts] = useState([]);
  const [isLoading, SetisLoading] = useState(true);

  useEffect(() => {
    getDocs(collection(db, 'games')).then((response) => {
      const games = response.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      SetProducts(games);
      SetisLoading(false);
    });
  }, []);

  const handleDownload = (filePath) => {
    const fileRef = ref(storage, filePath);

    getDownloadURL(fileRef)
      .then((url) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = filePath; // Descargar con el nombre original del archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.error("Error al descargar el archivo:", error);
      });
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="relative flex flex-col items-center gap-8 p-8 min-h-screen text-white bg-gradient-to-b from-indigo-700 to-purple-900">
      <h1 className="text-5xl md:text-7xl font-bold text-center mb-12 text-white-400 ">
        GAMES
      </h1>
      {Products.map((game) => (
        <div
          key={game.id}
          className="relative bg-gray-800 bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-2xl border-white-600 rounded-lg p-9 w-full mb-8"
        >
          <video
            src={game.video}
            type="video/mp4"
            autoPlay
            muted
            loop
            className="absolute top-0 left-0 w-full h-full object-cover rounded-lg opacity-50"
          />
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-black to-transparent opacity-70 rounded-lg" />
          <div className="flex flex-col md:flex-row gap-6 relative z-10">
            <img
              src={game.image}
              alt={game.name}
              className="w-full md:w-1/3 h-auto object-cover rounded-lg"
            />
            <div className="flex flex-col justify-between flex-grow">
              <div>
                <h2 className="text-blue-400 font-bold text-3xl mb-4">{game.name}</h2>
                <p className="text-gray-300 mb-4 text-lg">{game.description}</p>
              </div>
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-lg self-start transition-colors"
                onClick={() => handleDownload(game.zipPath)} // Asegúrate de que game.zipPath contenga el nombre del archivo ZIP
              >
                Download for Windows
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Games;

