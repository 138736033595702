import React from 'react';

const Donations = () => {
  return (
    <section className="bg-black py-12">
      <div className="container mx-auto max-w-screen-md px-4">
        <h2 className="text-4xl md:text-5xl font-bold mb-8 text-blue-400 text-center">Support Our Mission</h2>
        <p className="mb-4 text-lg text-custom text-blue-400">
          Our games are completely free, and we want to keep them accessible starble to everyone. However, developing these games and maintaining our website involves costs that we cover with our own effort and resources. That's why we greatly appreciate any contribution you can provide.
        </p>
        <p className="mb-4 text-lg text-custom">
          Your donation will help us:
        </p>
        <ul className="list-disc list-inside mb-8 text-lg text-custom">
          <li>Cover the costs of developing new video games.</li>
          <li>Maintain and improve our website.</li>
          <li>Continue offering educational and quality content inspired by Christian values.</li>
        </ul>
        <p className="mb-8 text-lg text-custom">
          We are grateful for your generosity and support, as it allows us to keep growing and be a blessing to many children, providing them with a safe and enriching space to learn and play. Thank you for being part of our mission at TenByte Games!
        </p>
        <div className="text-center text-custom">
          <a href="https://www.paypal.com/donate/?hosted_button_id=8M3DSPLX423HC" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full inline-block" target="_blank" rel="noopener noreferrer">
            Donate
          </a>
        </div>
      </div>
    </section>
  );
}

export default Donations;
